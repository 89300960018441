import ErrorModal from '@tb-core/components/container/error-modal';
import PageProvider, {
    PageContextProps
} from '@tb-core/components/context/webpage';
import WebpageLayout from '@tb-core/components/layout/webpage';
import BottomBody from '@tb-core/components/regions/bottom-body';
import TopBody from '@tb-core/components/regions/top-body';
import AuthHeader from '@tb-public/components/auth/auth-header';
import LoginMain from '@tb-public/components/container/login-main';

const LoginPage = (props: PageContextProps) => (
    <PageProvider {...props}>
        <WebpageLayout
            regions={{
                bottomBody: (
                    <BottomBody>
                        <ErrorModal />
                    </BottomBody>
                ),
                header: <AuthHeader />,
                main: <LoginMain />,
                topBody: <TopBody inlineSvgs={props.content.pageData?.icons} />
            }}
        />
    </PageProvider>
);

export default LoginPage;
