import { track } from '@amplitude/analytics-browser';
import { useEffect } from 'react';

import { amplitudeInitialize } from '@tb-core/helpers/analytics/amplitude';
import PasswordlessMain from '@tb-public/components/container/passwordless-main';

const LoginMain = () => {
    useEffect(() => {
        amplitudeInitialize();
        localStorage.setItem('redirect', document.referrer);
        track('Viewed Page/Screen', {
            page_path: '/login',
            page_title: 'Log In',
            screen_name: 'Welcome Home'
        });
    }, []);

    return <PasswordlessMain />;
};

export default LoginMain;
