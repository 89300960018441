import { PageContextProps } from '@tb-core/components/context/webpage';
import { getServerSidePropsInit } from '@tb-core/next/server-side-props-hooks/get-page-props-init';
import LoginPage from '@tb-public/components/container/login-page';

// Our GetServerSideProps page hook.
export const getServerSideProps = getServerSidePropsInit({});

// This is to allow for content author to test out multiple experiences of the login page
const Login = (props: PageContextProps) => <LoginPage {...props} />;

export default Login;
